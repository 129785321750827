// import Language_selector_router from '@/component/language/language_selector_router/language_selector_router';
import X_modal_feedback from '@/component/x/x_modal/feedback/x_modal_feedback/x_modal_feedback';
import { Context_auth } from '@/context/context_auth';
import useHrefAuth from '@/hook/auth/useHrefAuth';
import { useLogo } from '@/hook/ui/useLogo';
import { useTranslation } from 'next-i18next';
import { FC, memo, useContext, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import A from '../link/a/a';

export interface I_footer {
  ctn?: boolean;
  className?: string;
  hide_logo?: boolean;
}

const Footer: FC<I_footer> = memo((props: I_footer) => {
  const { t } = useTranslation();
  const { ctn, hide_logo, className } = props;
  const [domain, set_domain] = useState<string>('');
  const [year, set_year] = useState<number>();
  const logo = useLogo();
  const { signer } = useContext(Context_auth);
  const login = useHrefAuth('login', true);

  useEffect(() => {
    set_domain(window.location.hostname);
    set_year(new Date().getFullYear());
  }, []);

  return (
    <div data-testid="Footer" className={twMerge(`my-[50px] text-center text-sm text-foreground/60`, className)}>
      <div className={twMerge('c gap-2', ctn ? 'ctn' : '')}>
        {!hide_logo && (
          <div>
            <img className="max-w-[60px] max-h-[20px] inline-block opacity-80" src={logo} alt="Logo" />
          </div>
        )}
        <div className="r gap-2 flex-wrap items-center justify-center content_export_hidden">
          <A href="/check">看房检查单</A>
          {signer?.id ? <X_modal_feedback /> : <A href={login}>{t('common:feedback')}</A>}
          <A href="/reviewer/join" className="capitalize">
            {t('router:$reviewer.join')}
          </A>
        </div>
        <div className="r gap-2 flex-wrap items-center justify-center content_export_hidden">
          <A href="/about" className="capitalize">
            {t('router:about')}
          </A>
          <A href="/contact" className="capitalize">
            {t('router:contact')}
          </A>
          <A href="/privacy" className="capitalize">
            {t('router:privacy')}
          </A>
          <A href="/terms" className="capitalize">
            {t('router:terms')}
          </A>
        </div>
        <div className="trivial">
          <div>
            {domain.replace('www.', '')} © {year}
          </div>
          <a className="trivial" href="https://beian.miit.gov.cn" target="_blank">
            陕ICP备16002288号
          </a>
        </div>
        {/*<div>*/}
        {/*  <Language_selector_router />*/}
        {/*</div>*/}
        <div className="c items-center">
          <img
            className="w-[110px] dark:invert mt-1 opacity-80"
            src="https://wozuguo-public.oss-cn-hangzhou.aliyuncs.com/wozuguo.com/image/body_centered.png"
            alt="Logo extended"
          />
        </div>
      </div>
    </div>
  );
});

export default Footer;
