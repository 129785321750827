import Form_feedback from '@/component/form/form_feedback/form_feedback';
import X_modal from '@/component/x/x_modal/x_modal';
import { useTranslation } from 'next-i18next';
import { LinkProps } from 'next/dist/client/link';
import React, { FC, HTMLAttributes, memo, ReactNode } from 'react';
import { Optional } from 'utility-types';

export interface I_X_modal_feedback extends Optional<LinkProps & HTMLAttributes<HTMLAnchorElement>> {
  trigger?: ReactNode;
}

const X_modal_feedback: FC<I_X_modal_feedback> = memo<I_X_modal_feedback>(
  ({ trigger, ...rest }: I_X_modal_feedback) => {
    const { t } = useTranslation();

    return <X_modal query={'feedback'} trigger={trigger || t('common:feedback')} body={<Form_feedback />} {...rest} />;
  },
);

export default X_modal_feedback;
